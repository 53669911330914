import flutterwave from "../assets/flutterwave.webp";
import monnify from "../assets/monnify.webp";

const Partners = () => {
  return (
    <section className="flex items-center justify-center sm:justify-start sm:pl-10 xl:pl-0">
      <div className="p-6 mt-12 shadow-lg w-[300px] smPro:w-[350px]">
        <p className="font-bold text-[#032247]">Partners</p>
        <div className="flex justify-between items-center pt-6 max-w-[270px]">
          <img
            src={flutterwave}
            alt="Flutterwave logo"
            className="h-[18px] smPro:h-5"
          />
          <img src={monnify} alt="Monnify logo" className="h-[18px] smPro:h-5" />
        </div>
      </div>
    </section>
  );
};

export default Partners;
