const BlinkCardsItem = ({ title, description, bgColor, image }) => {
  return (
    <>
      <div
        className={`${bgColor} h-[500px] sm:h-[400px] md:h-[450px] lg:h-[413px] rounded-2xl text-[#032247] pt-20 wow animate__animated animate__zoomIn flex justify-between`}
      >
        <div className="max-w-[70%]">
          <h2 className="pl-2 sm:pl-8 font-extrabold text-3xl sm:text-4xl mb-4">
            {title}
          </h2>
          <p className="mt-10 px-2 sm:px-6 md:pl-8 leading-relaxed md:leading-[35px] lg:leading-relaxed md:w-[80%] lg:w-full text-lg lg:text-xl ">
            {description}
          </p>
        </div>

        <div>
          <img src={image} alt={title} />
        </div>
      </div>
    </>
  );
};

export default BlinkCardsItem;
