import { Outlet } from "react-router-dom";
import Header from "../common/Header/Header";
import Footer from "../common/Footer";
import { Helmet } from "react-helmet-async";

export default function RootLayout() {
  return (
    <>
      <Helmet>
        <title>Blink</title>
        <meta
          name="description"
          content="Blink is the digital payment platform transforming financial inclusion in Nigeria. Facilitate seamless cross-border, mobile money, and e-commerce payments across Africa."
        />
        <meta
          name="keywords"
          content="Africa payment gateway, Unified payment platform Africa, Digital payments Africa, Cross-border payments Africa, Mobile money Africa, Online payment solutions Africa, Fintech solutions Africa, Financial inclusion Africa, Cashless transactions Africa, E-commerce payments Africa, B2B payment processing Africa, P2P money transfer Africa, Remittance services Africa, Merchant payment acceptance Africa, Blink payment platform"
        />
      </Helmet>

      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
