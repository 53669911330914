import joinUs from "../assets/JoinUs.webp";
import EmailValidation from "./EmailValidation";

const JoinUs = () => {
  return (
    <section className="mb-20 lg:mb-40 lg:mt-10 relative bg-[#0064FF] bg-opacity-10 p-6 lg:p-10 xl:mx-1 rounded-2xl mx-2 smPro:mx-4 sm:mx-8 md:mx-20 h-60 smPro:h-64 sm:h-72 lg:h-full wow animate__animated animate__fadeInUp" id="waitlist">
      <div className="lg:max-w-[400px] xl:max-w-[500px]">
        <h2 className="font-bold text-2xl smPro:text-3xl sm:text-4xl leading-tight">
          Want a seamless means of Payment?
        </h2>
        <p className="py-6 text-xl sm:text-2xl md:text-xl font-medium">Join Us Today!</p>
        <EmailValidation />
      </div>

      <div className="absolute lg:-top-[58px] xl:-top-[78px] right-10 hidden lg:block">
        <img src={joinUs} alt="Join Us" className="lg:h-[350px] xl:h-[370px]" />
      </div>
    </section>
  );
};

export default JoinUs;
