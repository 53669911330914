import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Hamburger, HamburgerClose } from "../../svg/svgs";
import Nav from "./Nav";
import logo from "../../assets/logo2.webp";

export default function Header() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [navbarBgColor, setNavbarBgColor] = useState(false);

  const toggleNavbar = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const changeBgColor = () => {
    if (window.scrollY >= 80) {
      setNavbarBgColor(true);
    } else {
      setNavbarBgColor(false);
    }
  };

  window.addEventListener("scroll", changeBgColor);

  useEffect(() => {
    if (menuIsOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [menuIsOpen]);

  return (
    <header
      className={`${
        navbarBgColor ? "bg-white shadow-xl" : "bg-slate-50"
      } sticky top-0 z-[20]`}
    >
      <section className="max-w-[1200px] m-auto font-jakarta">
        <div className="flex justify-between items-center lg:mr-6">
          <div className="flex justify-between w-full lg:w-[100px] py-2 px-2 sm:px-16 lg:px-6 xl:px-8">
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                className="h-14 sm:h-16 mr-20"
                onClick={() => {
                  setMenuIsOpen(!menuIsOpen);
                }}
              />
            </Link>

            <div onClick={toggleNavbar} className="lg:hidden">
              {menuIsOpen ? <HamburgerClose /> : <Hamburger />}
            </div>
          </div>

          <div className="hidden lg:flex">
            <Nav />
          </div>
        </div>
      </section>

      <div
        className={`lg:hidden h-full fixed w-full transition-all ease-in duration-300 ${
          navbarBgColor ? "bg-white" : "bg-slate-50"
        } ${menuIsOpen ? " opacity-100 visible" : "opacity-0 invisible"}`}
      >
        <Nav />
      </div>
    </header>
  );
}
