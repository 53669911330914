import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import { ArrowRight, Loading } from "../svg/svgs";

export default function EmailValidation() {
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [emailIsEmpty, setEmailIsEmpty] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [messageTimerId, setMessageTimerId] = useState(null);
  const [displayRecaptcha, setDisplayRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);
    setEmailIsValid(validateEmail(newEmail));
    setEmailIsEmpty(capitalizeFirstLetter(newEmail));

    if (newEmail.trim() !== "") {
      setEmailIsEmpty("");
      setError("");
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  let newMessageTimerId;

  const handleRecaptchaError = () => {
    setError("Unable to connect. Please check your internet connection.");
    setEmail("");
    setLoading(false);
    setDisplayRecaptcha(false);

    newMessageTimerId = setTimeout(() => {
      setError("");
    }, 3000);
    setMessageTimerId(newMessageTimerId);
  };

  const handleRecaptchaExpired = () => {
    setError("Session expired. Please try again.");
    setEmail("");
    setLoading(false);
    setDisplayRecaptcha(false);

    newMessageTimerId = setTimeout(() => {
      setError("");
    }, 3000);
    setMessageTimerId(newMessageTimerId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      setEmailIsEmpty(capitalizeFirstLetter("email is a required field"));
      return;
    }
    setLoading(true);

    setDisplayRecaptcha(true);
  };

  const handleRecaptchaSubmit = async () => {
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}`, { email })
      .then((res) => res.data)
      .catch((error) => error.response.data);

    if (String(response.statusCode).includes(4)) {
      setError(response.message);
      setDisplayRecaptcha(false);
      setLoading(false);

      newMessageTimerId = setTimeout(() => {
        setError("");
        setEmail("");
      }, 3000);
      setMessageTimerId(newMessageTimerId);
      return;
    }

    setSuccessMessage(response.data.message);
    setEmail("");
    setDisplayRecaptcha(false);
    setLoading(false);

    newMessageTimerId = setTimeout(() => {
      setSuccessMessage("");
    }, 3000);
    setMessageTimerId(newMessageTimerId);
  };

  useEffect(() => {
    return () => {
      clearTimeout(messageTimerId);
    };
  }, [messageTimerId]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-center items-center relative">
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleEmailChange}
            className="bg-white w-full pl-4 py-3 sm:py-4 outline-none rounded-3xl relative"
          />
          <button
            type="submit"
            className="absolute right-0 bg-black rounded-full mr-2 p-2"
          >
            {loading ? <Loading /> : <ArrowRight />}
          </button>
        </div>

        {displayRecaptcha && (
          <div className="absolute scale-[0.8] smPro:mt-2 smPro:transform-none left-0 right-20 smPro:left-[unset] smPro:right-[unset]">
            <ReCAPTCHA
              sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}
              onChange={handleRecaptchaSubmit}
              onErrored={handleRecaptchaError}
              onExpired={handleRecaptchaExpired}
            />
          </div>
        )}

        {!emailIsValid && (
          <p className="absolute text-red-600 text-base smPro:text-lg sm:text-lg md:text-lg font-semibold">
            Invalid email format
          </p>
        )}

        {emailIsEmpty && (
          <p className="absolute text-red-600 text-base smPro:text-lg sm:text-lg md:text-lg font-semibold">
            {capitalizeFirstLetter(emailIsEmpty)}
          </p>
        )}

        {error ? (
          <p className="absolute text-red-600 text-base smPro:text-lg sm:text-lg md:text-lg font-semibold">
            {error}
          </p>
        ) : (
          <p className="absolute text-green-600 text-base smPro:text-lg sm:text-lg md:text-lg font-semibold">
            {successMessage}
          </p>
        )}
      </form>
    </>
  );
}
