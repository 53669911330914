import BlinkCardsItem from "./BlinkCardsItem";
import addCard from "../../assets/AddCard.webp";
import scanToPay from "../../assets/scanToPay.webp";
import tapToPay from "../../assets/tapToPay.webp";
import nearbyPayment from "../../assets/nearbyPayment.webp";

const BlinkCards = () => {
  return (
    <section className="py-28 xl:pb-48 px-2 smPro:px-4 sm:px-8 xl:px-1">
      <div className="max-w-[600px] xl:pl-4 mb-10 xl:mb-10 wow animate__animated animate__fadeInUp">
        <h1 className="text-[41px] sm:text-[65px] lg:text-5xl leading-tight font-bold mb-6 text-[#032247]">
          Do more with Blink.
        </h1>
        <p className="text-xl sm:text-3xl lg:text-2xl text-[#2d4766] pt-4">
          Seamlessly make contactless payments on your device by
          doing the following.
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-[#FFD9D9] col-span-full h-[500px] sm:h-[400px] md:h-[450px] lg:h-96 rounded-2xl flex justify-between overflow-hidden wow animate__animated animate__zoomIn">
          <div className="max-w-[700px] text-[#032247] pt-20 px-2">
            <h2 className="pl-2 sm:pl-8 font-extrabold text-3xl sm:text-4xl mb-4">
              Add your favorite bank card
            </h2>

            <p className="mt-10 px-2 sm:px-6 md:pl-8 leading-relaxed md:leading-[35px] lg:leading-relaxed md:w-[80%] lg:w-[90%] text-lg md:text-xl">
              Pay with ease and take full control of your action with confidence
              in anywhere, everywhere and anytime.
            </p>
          </div>

          <div className="hidden md:flex items-center justify-center">
            <img
              src={addCard}
              alt="Add your favorite bank card"
              className="lg:h-96"
            />
          </div>
        </div>

        <div className="bg-[#D9F4FF] h-[500px] sm:h-[400px] md:h-[450px] lg:h-[850px] rounded-2xl pt-20 flex flex-col sm:flex-row lg:flex-col justify-between items-center sm:items-start lg:items-center wow animate__animated animate__zoomIn">
          <div className="px-4 max-w-[700px] lg:max-w-[100%]">
            <h2 className="sm:pl-8 font-extrabold text-3xl sm:text-4xl mb-4">
              Scan To Pay
            </h2>

            <p className="mt-10 sm:px-6 md:pl-8 leading-relaxed md:leading-[35px] lg:leading-relaxed md:w-[80%] lg:w-[90%] text-lg md:text-xl">
              No card? No problem. Just use your in-app camera to scan the
              merchant’s Quick Response (QR) code and pay with ease.
            </p>
          </div>

          <div>
            <img
              src={scanToPay}
              alt="Scan To Pay"
              className="h-48 sm:h-full lg:h-[450px] xl:h-[500px]"
            />
          </div>
        </div>

        <div className="grid grid-rows-2 gap-6">
          <BlinkCardsItem
            title="Tap To Pay"
            description="Blink lets you use your smartphone to pay by just tapping it wherever they accept NFC payments. This makes buying stuff in stores fast and safe without the need of a debit or credit card."
            bgColor="bg-[#E7FFC8]"
            image={tapToPay}
          />
          <BlinkCardsItem
            title="Make Nearby Payments"
            description="Use Geolocation to help you locate nearby merchants just within a few meters. Select the one you wish to pay and the merchant accepts your payment."
            bgColor="bg-[#ECD9FF]"
            image={nearbyPayment}
          />
        </div>
      </div>
    </section>
  );
};

export default BlinkCards;
