import { ArrowDown, ArrowUp } from "../../svg/svgs";

export const FAQsItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="w-full px-6 sm:px-0 sm:w-[550px] md:w-[600px] lg:w-[700px]">
        <div
          className="flex justify-between items-center border-b-[1.5px] border-slate-200 cursor-pointer"
          onClick={onClick}
        >
          <h2 className="text-lg sm:text-2xl text-[#2d4766] font-semibold pt-8 pb-2">
            {question}
          </h2>

          <div className="cursor-pointer pt-8 pb-2">
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </div>
        </div>

        <div
          className={`overflow-hidden transition-[max-height] duration-300 ${
            isOpen ? "max-h-96" : "max-h-0"
          }`}
        >
          <p className="text-base sm:text-xl leading-6 py-2 text-[#2d4766]">
            {answer}
          </p>
        </div>
      </div>
    </div>
  );
};
