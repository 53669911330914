import { NavLink } from "react-router-dom";

export default function Nav() {
  return (
    <nav className="font-jakarta font-medium text-sm lg:text-[15px]">
      <ul className="flex flex-col lg:flex-row justify-center items-center">
        <li className="mb-8 lg:mb-0 lg:pr-8 cursor-pointer">Blink for Business</li>

        <li className="mb-8 lg:mb-0 lg:pr-8 cursor-pointer">
          <NavLink
            to="/faq"
            className={({ isActive }) =>
              isActive ? "relative text-[#032247]" : undefined
            }
          >
            {({ isActive }) => (
              <>
                FAQ
                {isActive && (
                  <span className="w-2 h-2 bg-[#032247] absolute  rounded-full left-[40%] top-[100%]"></span>
                )}
              </>
            )}
          </NavLink>
        </li>

        <li className="mb-8 lg:mb-0 lg:pr-20 cursor-pointer">
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              isActive ? "relative text-[#032247]" : undefined
            }
          >
            {({ isActive }) => (
              <>
                Contact Us
                {isActive && (
                  <span className="w-2 h-2 bg-[#032247] absolute  rounded-full left-[40%] top-[100%]"></span>
                )}
              </>
            )}
          </NavLink>
        </li>

        <li>
          <button className="border rounded-full py-3 px-6 text-white bg-[#032247] font-normal mb-8 lg:mb-0">
            Get started
          </button>
        </li>
      </ul>
    </nav>
  );
}
