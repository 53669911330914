import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import RootLayout from "./pages/Root";
import HomePage from "./pages/Home";
import { ContactUs } from "./pages/ContactUs";
import { FAQs } from "./pages/FAQ/FAQs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/faq", element: <FAQs /> },
    ],
  },
]);

export default function App() {
  return (
    <>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </>
  );
}
