import { AdvanceLevelOfSecurityItem } from "./AdvanceLevelOfSecurityItem";
import dataSecurity from "../../assets/DataSecurity.webp";
import protectedPin from "../../assets/ProtectedPin.webp";
import faceId from "../../assets/Face ID.webp";

export const AdvanceLevelOfSecurity = () => {
  return (
    <>
      <section className="pb-28 pt-24 bg-[url('/src/assets/advancedBackground.webp')] w-full">
        <div className="max-w-[1140px] m-auto">
          <div className="wow animate__animated animate__fadeInUp flex flex-col items-center justify-center text-center">
            <div className="text-white">
              <h1 className="font-bold text-[41px] sm:text-[65px] lg:text-5xl px-3 smPro:px-4">
                Get an advance level of security
              </h1>
              <p className="font-normal text-xl sm:text-3xl lg:text-2xl py-10 px-6 max-w-[900px]">
                Blink works in-store, in-app and online. Load your cards onto
                your devices and check out with a tap.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 px-4 smPro:px-6 sm:px-20 md:px-16 xl:px-16 gap-8">
            <AdvanceLevelOfSecurityItem
              title="Data Security"
              description="Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime."
              image={dataSecurity}
            />
            <AdvanceLevelOfSecurityItem
              title="Protected Pin"
              description="Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime."
              image={protectedPin}
            />
            <AdvanceLevelOfSecurityItem
              title="Biometrics"
              description="Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime."
              image={faceId}
            />
          </div>
        </div>
      </section>
    </>
  );
};
