import HeroImageNew from "../assets/HeroImageNew.webp";
import { ArrowRight } from "../svg/svgs";
import DownloadApp from "../components/DownloadApp";
import Partners from "./Partners";

const Hero = () => {
  return (
    <section className="min-h-screen flex flex-col lg:flex-row justify-between items-center mb-20 xl:mb-10 animate__animated animate__fadeInUp">
      <div className="lg:w-[50%]">
        <div className="text-center lg:text-start lg:pl-6 xl:pl-0 pt-28 xl:pt-0">
          <h1 className="text-[41px] sm:text-[65px] lg:text-5xl font-bold mb-8 leading-tight smPro:leading-normal sm:leading-snug lg:leading-tight xl:leading-tight text-[#032247]">
            Unified Payment Interface for Africa
          </h1>
          <p className="text-xl sm:text-3xl lg:text-2xl px-4 md:px-0 text-[#2d4766]">
            Pay with ease and take full control of your action with confidence
            anywhere, everywhere and anytime.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row items-center sm:justify-center lg:justify-start mt-10 lg:pl-6 xl:pl-0">
          <a href="#waitlist">
            <button className="bg-[#032247] text-white font-semibold flex justify-center items-center px-8 py-3.5 sm:mr-6 mb-6 sm:mb-0 rounded-full">
              <span className="pr-3">Join the waitlist</span>
              <ArrowRight />
            </button>
          </a>

          <div className="bg-[#71879C0D] rounded-full shadow-md">
            <DownloadApp />
          </div>
        </div>

        <div>
          <Partners />
        </div>
      </div>

      <div className="mt-20 px-4 sm:px-0 lg:pr-6 xl:pr-0">
        <img src={HeroImageNew} alt="Hero" />
      </div>
    </section>
  );
};

export default Hero;
