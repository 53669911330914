import "animate.css";
import WOW from "wowjs";
import { useEffect } from "react";

import BlinkCards from "../components/BlinkCards/BlinkCards";
import { AdvanceLevelOfSecurity } from "../components/AdvancedLevelOfSecurity/AdvanceLevelOfSecurity";
import Hero from "../components/Hero";
import CheckOut from "../components/CheckOut";
import JoinUs from "../components/JoinUs";
import Reviews from "../components/Reviews/Reviews";

export default function HomePage() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <section className="font-jakarta bg-slate-50 flex flex-col justify-center items-center">
        <div className="max-w-[1150px]">
          <Hero />
        </div>

        <AdvanceLevelOfSecurity />

        <div className="max-w-[1150px]">
          <BlinkCards />

          <CheckOut />

          <Reviews />

          <JoinUs />
        </div>
      </section>
    </>
  );
}
