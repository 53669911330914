import { Link } from "react-router-dom";
import { useEffect } from "react";
import WOW from "wowjs";

import { ArrowSide, Quote } from "../svg/svgs";
import DownloadApp from "../components/DownloadApp";

export const ContactUs = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <section className="font-jakarta bg-slate-50 flex flex-col justify-center items-center">
        <div className="wow animate__animated animate__fadeInUp">
          <div className="flex flex-col justify-center items-center pt-20 xl:pt-28">
            <h1 className="font-bold xl:font-semibold text-4xl sm:text-5xl lg:text-6xl xl:text-7xl text-[#032247]">
              How can we help?
            </h1>

            <p className="text-[#2d4766] text-center text-md sm:text-xl font-medium max-w-[300px] sm:max-w-[500px] lg:max-w-[560px] mt-6 xl:mt-10">
              Looking for the solution that best fits your business needs?
              Contact our support team and we will be in touch.
            </p>
          </div>

          <div className="mt-14 sm:mt-20 mb-24 grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="w-[300px] smPro:w-[350px] sm:w-[500px] md:w-[320px] lg:w-[400px] xl:w-[500px]">
              <div className="bg-white rounded-xl p-8 md:p-12 shadow-xl">
                <img
                  src="https://a.storyblok.com/f/118499/x/a8bda73874/icon_find-answers-you-need-1.svg"
                  alt="FAQ"
                  className="w-16 h-16"
                />
                <h4 className="text-xl smPro:text-2xl sm:text-3xl md:text-2xl xl:text-3xl font-bold leading-snug py-6 max-w-[300px] md:max-w-[300px]">
                  Find the answers you need
                </h4>

                <Link to="/faq">
                  <div className="text-[#0055BA] flex items-center text-sm">
                    <p className="font-bold">FAQs</p>
                    <ArrowSide />
                  </div>
                </Link>
              </div>
            </div>

            <div className="w-[300px] smPro:w-[350px] sm:w-[500px] md:w-[320px] lg:w-[400px] xl:w-[500px]">
              <div className="bg-white rounded-xl p-8 md:p-12 shadow-xl">
                <img
                  src="https://a.storyblok.com/f/118499/x/a8bda73874/icon_find-answers-you-need-1.svg"
                  alt="Email"
                  className="w-16 h-16"
                />
                <h4 className="text-xl smPro:text-2xl sm:text-3xl md:text-2xl xl:text-3xl font-bold leading-snug py-6 max-w-[300px] md:max-w-[300px]">
                  Send our Support team an email
                </h4>

                <div>
                  <Link to="mailto:hello@useblink.co">
                    <div className="text-[#0055BA] flex items-center text-sm">
                      <p className="font-bold">email</p>
                      <ArrowSide />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#032247] w-full wow animate__animated animate__fadeInUp">
          <div className="flex flex-col items-center justify-center text-white pt-40 md:pt-60 pb-40 text-center px-4 md:px-0">
            <h2 className="text-3xl sm:text-4xl md:text-5xl xl:text-6xl font-semibold md:font-bold lg:max-w-[600px] leading-snug sm:leading-tight md:leading-snug">
              Accept contactless payment with Blink.
            </h2>
            <p className="text-md sm:text-xl md:text-[21px] font-medium py-6 max-w-[700px] sm:max-w-[480px] xl:max-w-[600px]">
              Supercharge your payment process using diverse set of tools that
              powers contactless payments from your mobile device.
            </p>
            <div className="border rounded-full mt-8">
              <DownloadApp />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-20 mb-40 wow animate__animated animate__fadeInUp">
          <h2 className="text-4xl md:text-6xl font-bold md:font-semibold text-[#032247]">
            Contact Sales
          </h2>

          <p className="text-xl md:text-2xl text-[#2d4766] max-w-[600px] sm:max-w-[400px] md:max-w-[600px] my-4 text-center">
            Tell us what your business needs, and we'll find the best solution
            for you.
          </p>

          <div className="max-w-[800px] sm:max-w-[500px] md:max-w-[800px] xl:max-w-[900px] mt-12 px-6 md:px-20 lg:px-0">
            <Quote />
            <h3 className="text-[#032247] text-2xl md:text-4xl xl:text-5xl font-semibold leading-snug md:leading-normal xl:leading-snug">
              Blink helps businesses and individuals simplify their payment
              processes and flows, and we’re here to give you the right tools to
              build the future of payments today, send us a message.
            </h3>
          </div>

          <div className="flex items-center w-[85%] md:w-[80%] lg:w-[100%] mt-6">
            <div>
              <h6 className="text-lg font-bold text-[#2d4766]">
                Okoro Divinewill
              </h6>
              <span className="text-slate-500">Co-founder, Blink</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
