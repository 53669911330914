import { useState } from "react";
import { FAQsItem } from "./FAQsItem";

export const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="font-jakarta flex justify-center items-center pb-28 bg-slate-50 h-screen">
      <div className="wow animate__animated animate__fadeInUp">
        <div className="text-center py-8">
          <h1 className="text-4xl sm:text-5xl lg:text-[55px] font-bold text-[#032247]">
            Frequently Asked Questions
          </h1>
          <h6 className="text-lg font-semibold text-[#2d4766] pt-2 smPro:px-6 sm:px-0">
            Got Questions? Answers and advice from the Blink Team
          </h6>
        </div>
        <FAQsItem
          index={0}
          isOpen={activeIndex === 0}
          onClick={() => toggleFAQ(0)}
          question="What is Blink?"
          answer="Blink is a cutting-edge data payment system that allows you to make and receive payments, between businesses and customers through an advanced tech called Near Field Communication– all of this at the blink of an eye!"
        />
        <FAQsItem
          index={1}
          isOpen={activeIndex === 1}
          onClick={() => toggleFAQ(1)}
          question="How does NFC work?"
          answer="Near Field Communication is a wireless technology that allows two devices to communicate. NFC-enabled payment apps, like Blink, allows your phone to communicate with the receiver and thus initiates payment by sharing encrypted information within seconds."
        />
        <FAQsItem
          index={2}
          isOpen={activeIndex === 2}
          onClick={() => toggleFAQ(2)}
          question="Does Blink accept all cards?"
          answer="Blink works with major debit cards such as Verve, Visa, and MasterCard, offering customers flexibility and convenience and continuously integrating more banks into its network."
        />
        <FAQsItem
          index={3}
          isOpen={activeIndex === 3}
          onClick={() => toggleFAQ(3)}
          question="What support options does Blink offer to its customers?"
          answer="Blink provides dedicated customer support channels, including email, phone, and live chat, guaranteeing swift assistance and resolution for any inquiries or challenges encountered throughout transactions or integration processes."
        />
      </div>
    </section>
  );
};
